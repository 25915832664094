<template>
  <div class="inpatient_container">
    <el-row class="inpatient_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <div class="file_row">
      <div class="input">
        <span>住院号:</span>
        <input v-model="admissionNum" class="cdrNum" />
      </div>
      <div class="input">
        <span>名字:</span>
        <input v-model="patName" class="cdrNum" />
      </div>
      <el-button type="primary" class="search" @click.native="getHospitalizationFeesListSummary">查询</el-button>
    </div>
    <div class="info">
      <div v-if="isShow">科室：{{ dept }},床位：{{ bedNo }},入院时间:{{ inDate }},住院总费用:{{ totalFee }}</div>
    </div>
    <div class="inpatient_main">
      <el-table :data="tableData" v-loading="loading" border="" style="width: 100%" ref="multipleTable" highlight-current-row :row-class-name="tableRowClassName" @row-click="toggleSelection">
        <el-table-column type="index"> </el-table-column>
        <!-- <el-table-column type="selection"
                         width="60"></el-table-column> -->
        <el-table-column label="费用日期" prop="patName">
          <template slot-scope="scope">
            <span>{{ scope.row.itemDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="当天费用价格合计" prop="deptName">
          <template slot-scope="scope">
            <span>{{ scope.row.itemTotalFee }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div>
        <img :src="'data:image/png;base64,'+imgurl"
             alt="">
      </div> -->
    </div>
    <div class="tip">温馨提示：点击表格中的行可查看当天的费用详情</div>
    <el-button type="primary" @click.native="home" class="home">首页</el-button>
    <!-- <el-button type="primary"
               @click.native="back"
               class="back">返回</el-button> -->
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost } from '@/baseAPI'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      value: '',
      show: false,
      name: '',
      tableData: [],
      loading: false,
      admissionNum: '',
      patName: '',
      timeSlot: '',
      beginDate: '',
      endDate: '',
      dept: '',
      bedNo: '',
      inDate: '',
      totalFee: '',
      isShow: false
    }
  },
  created() {
    this.name = this.$route.query.name
    this.patCardNo = this.$route.query.patCardNo
    this.day()
    if (this.$route.query.isBack == 1) {
      this.admissionNum = this.$route.query.admissionNum
      this.patName = this.$route.query.patName
      this.getHospitalizationFeesListSummary()
    }
  },
  methods: {
    day() {
      let today = new Date()
      let tempDate = new Date()
      tempDate.setDate(tempDate.getDate() - 60)
      this.beginDate = this.formatDate(tempDate)
      this.endDate = this.formatDate(today)
    },
    formatDate(date) {
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    },
    // 住院清单
    async getHospitalizationFeesListSummary() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await jsonPost('his/getHospitalizationFeesListSummary', {
        admissionNum: this.admissionNum,
        beginDate: this.beginDate,
        endDate: this.endDate,
        patName: this.patName
      })
      if (res.code === 0) {
        loading.close()
        this.isShow = true
        this.tableData = res.data.item
        this.dept = res.data.deptName
        this.bedNo = res.data.bedNo
        this.inDate = res.data.inDate
        this.totalFee = res.data.totalFee
      } else {
        loading.close()
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },

    getNum() {},
    toggleSelection(row) {
      console.log(row)
      this.$router.push({
        path: '/inpatientDetail',
        query: {
          patName: this.patName,
          admissionNum: this.admissionNum,
          beginDate: row.itemDate,
          endDate: row.itemDate
        }
      })
    },
    tableRowClassName({ row, rowIndex }) {
      row.row_index = rowIndex
    },
    home() {
      this.$router.push('/home')
    },
    back() {
      this.$router.back()
    },
    recharge() {
      this.show = true
      // if (this.$refs.multipleTable.selection.length === 0) {
      //   alert('请选择住院记录项')
      // } else {
      //   this.show = true
      // }
    },
    inputNum(num) {
      this.value += num
    },
    confirm() {
      const that = this
      if (that.value === '') {
        this.$message({
          message: '金额不能为空',
          type: 'success'
        })
      } else if (that.value === 0 || that.value === '0') {
        this.$message({
          message: '金额不能为零',
          type: 'success'
        })
      } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(that.value)) {
        this.$message({
          message: '输入金额有误，请重新输入',
          type: 'success'
        })
      } else {
        that.$router.push({
          path: '/mode',
          query: {
            name: that.name,
            patName: that.tableData[0].patName,
            regFee: that.value,
            admissionNum: this.admissionNum
          }
        })
      }
    },
    del() {
      this.value = this.value.substr(0, this.value.length - 1)
    },
    clear() {
      this.value = ''
    },
    cancel() {
      this.show = false
      this.value = ''
    }
  }
}
</script>
<style lang="less" scoped>
.input {
  width: 45%;
  height: 100%;
}

/deep/.el-input__inner {
  border-color: #3b77d8 !important;
  margin-left: 15px;
  border: #3172ff 2px solid !important;
  border-radius: 8px;
  height: 100%;
  font-size: 46px !important;
  color: #000000 !important;
}

.bgc {
  background-color: red;
}

.inpatient_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.inpatient_container .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}

.inpatient_container .popup {
  position: fixed;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdfe6;

  height: 40%;
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 999;
}

.inpatient_container .popup h1 {
  text-align: center;
  color: red;
  letter-spacing: 10px;
  margin: 10px 0;
  font-size: 30px;
}

.inpatient_container .popup p {
  margin-top: 20px;
  color: red;
  font-size: 18px;
}

.inpatient_container .popup .btn {
  margin-top: 30px;
}

.inpatient_container .popup .btn .el-button {
  font-size: 20px !important;
}

.inpatient_container .popup .ipt {
  border: 2px solid #000;
  padding-left: 20px;
  font-size: 40px;
  width: 500px;
  height: 60px;
  margin: 0 auto;
  margin-top: 30px;
  line-height: 60px;
}

.inpatient_container .popup .keyboard {
  margin: 0 auto;
  width: 500px;
  margin-top: 20px;
}

.inpatient_container .popup .keyboard .num {
  width: 350px;
  float: left;
}

.inpatient_container .popup .keyboard .num .el-button {
  margin: 0;
  float: left;
  width: 60px;
  height: 60px;
  font-size: 40px !important;
  margin: 10px;
}

.inpatient_container .popup .keyboard .funtion {
  width: 100px;
  float: left;
}

.inpatient_container .popup .keyboard .funtion .el-button {
  margin: 0;
  width: 130px;
  height: 60px;
  font-size: 40px !important;
  margin: 10px;
}

.inpatient_container .popup .btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 0;
}

.inpatient_container .popup .btn .el-button {
  font-size: 30px !important;
  margin: 0 40px;
}

.inpatient_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.inpatient_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  position: absolute;
  bottom: 80px;
  right: 150px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}

.search {
  width: 220px;
  height: 120px;
  right: 30px;
  font-size: 26px !important;
}

.info {
  margin-top: 10%;
  text-align: center;
  color: red;
  font-size: 42px;
}

.inpatient_main {
  margin: 0 auto;
  width: 90%;
}

.tip {
  text-align: center;
  color: red;
  font-size: 42px;
}

.inpatient_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.inpatient_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
  background-clip: padding-box !important;
}

.inpatient_container .el-checkbox__inner {
  width: 30px !important;
  height: 30px !important;
  margin-top: -7px !important;
  margin-left: 20px !important;
}

.el-checkbox__inner::after {
  left: 7px !important;
  top: 4px !important;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 8px !important;
}

.recharge {
  height: 80px;
  position: absolute;
  bottom: 320px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px !important;
}

.file_row {
  height: 80px;
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  padding: 0 45px;
}

.file_row .cdrNum {
  width: 50%;
  font-size: 60px;
}

.file_row span {
  font-size: 60px;
  line-height: 80px;
}
</style>
